import React, { useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Affix, Layout, Menu, Drawer, Button } from "antd"
import PropTypes from "prop-types"
import logoMuvuNavbar from '../images/logoMuvuNavbar.svg'
import Info from "./Info"
import { MenuOutlined } from '@ant-design/icons'

const { Header } = Layout

const HeaderSite = ({ siteTitle }) => {
   const [visibleDrawer, setVisibleDrawer] = useState(false)

   const showDrawer = () => {
      setVisibleDrawer(true)
   }

   const closeDrawer = () => {
      setVisibleDrawer(false)
   }

   return (
      <Row justify="center" className="navbar-wrapper">
         <Col lg={24} className="info-desktop">
            <Info type="info-wrapper-home" />
         </Col>
         <Affix offsetTop={0} className="affix-content">
            <Header >
               <div className="logo-wrapper">
                  <Link to="/">
                     <img src={logoMuvuNavbar} alt="Logo Muvu" />
                  </Link>
               </div>
               <div>
                  <Button className="menu-mobile-button" onClick={showDrawer} shape="circle" icon={<MenuOutlined />} />
                  <Drawer
                     placement="right"
                     visible={visibleDrawer}
                     onClose={closeDrawer}
                     className="menu-drawer"
                  >
                     <Menu mode="vertical"  className="menu-mobile" triggerSubMenuAction="click">
                        <Menu.Item key="1">
                        </Menu.Item>
                     </Menu>
                     <Row justify="center">
                        <Col lg={24} xs={24} className="info-mobile">
                           <Info type="info-wrapper-home" />
                        </Col>
                     </Row>
                  </Drawer>
               </div>

               <Menu mode="horizontal" className="menu-desktop">
                  <Menu.Item key="1">
                   
                  </Menu.Item>
               </Menu>
            </Header>
         </Affix>
      </Row>
   )
}



Header.propTypes = {
   siteTitle: PropTypes.string,
}

Header.defaultProps = {
   siteTitle: ``,
}

export default HeaderSite
