import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import HeaderSite from "./header"
import Footer from "./Footer"
import 'antd/dist/antd.css'
import "./layout.scss"
import WhatsAppButton from "../components/WhatsAppButton"
import GoogleTagManager from "../components/GoogleTagManager";
import LinkedInAnalytics from "./LinkedInAnalytics";

const Layout = ({ location, children }) => {
   const data = useStaticQuery(graphql`
      query SiteTitleQuery {
         site {
            siteMetadata {
               title
            }
         }
      }
   `)

   const backgroundKlass = (location) => {

      if (location && location.pathname == '/') {
         return 'home-container'
      }

      if (location && location.pathname.indexOf('password') > -1) {
         return 'secondary-container'
      }

      return 'common-container'
   }

   return (
      <>
         <HeaderSite siteTitle={data.site.siteMetadata.title} />
         <div className={backgroundKlass(location)}>
            <main>{children}</main>
         </div>
         <Footer />
         <GoogleTagManager />
         <LinkedInAnalytics />
         <WhatsAppButton message="Hola, tengo una duda." />
      </>
   )
}

Layout.propTypes = {
   children: PropTypes.node.isRequired,
}

export default Layout
